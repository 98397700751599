import React from 'react'
import styled, { useTheme } from 'styled-components'
import { animated, AnimatedProps } from '@react-spring/web'

interface FaqToggleProps {
  onToggle?: (() => void) | (() => Promise<void>)
}

const SvgButton = styled(animated.svg)`
  :hover {
    cursor: pointer;
  }
`

export const FaqToggle: React.VFC<AnimatedProps<Record<string, unknown>> & FaqToggleProps> = ({
  onToggle,
  ...props
}) => {
  const theme = useTheme()

  return (
    <SvgButton width={18} height={18} viewBox="0 0 41 41" fill="none" onClick={onToggle} {...props}>
      <path d="M2 2L39.5 39.5" stroke={theme.colors.darkBlue} strokeWidth="3" />
      <path d="M2 39.5L39.5 2" stroke={theme.colors.darkBlue} strokeWidth="3" />
    </SvgButton>
  )
}
