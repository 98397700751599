import React from 'react'
import styled from 'styled-components'
import NewFoundLogo from 'components/utils/NewFoundLogo'
import BurgerMenu from 'components/BurgerMenu'
import { AmplitudeEventTypes, sendAmplitudeEvent } from 'services/amplitude'

interface HeaderProps {
  hideHeaderOnBreakpoint?: number
  logoFill?: string
  isAuthPage?: boolean
  isInApp?: boolean
}

const HeaderWrap = styled.div`
  height: 66px;
  .header-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    max-width: calc(100vw - 348px);
    margin: 0 ${({ theme }) => theme.spacing(3)}px;

    @media (max-width: ${props => props.theme.breakpoints.xl}px) {
      max-width: calc(100vw - 120px);
    }

    @media (max-width: ${props => props.theme.breakpoints.md}px) {
      max-width: unset;
      margin: 0 ${({ theme }) => theme.spacing(2)}px;
    }
  }

  .logo-area {
    display: flex;
    flex-grow: 1;
    a {
      display: flex;
      text-decoration: none;
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${props => props.theme.breakpoints.md}px) {
      .link {
        display: none;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.md + 1}px) {
      .burger-menu {
        display: none;
      }
    }

    a {
      text-decoration: none;
      font-weight: 700;
      display: block;
      margin: ${({ theme }) => theme.spacing(1)}px;
      color: ${({ theme }) => theme.colors.darkGreen};
    }

    .continue {
      background-color: ${({ theme }) => theme.colors.darkGreen};
      color: ${({ theme }) => theme.colors.white};
      padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(4)}px;
      border-radius: 2px;
    }
  }

  border-bottom: 1px solid ${props => props.theme.colors.gray};
`

const Header = ({ logoFill }: HeaderProps) => {
  const toPrePurchase = (e: React.MouseEvent) => {
    e.preventDefault()
    sendAmplitudeEvent(AmplitudeEventTypes.RedirectToPrePurchase)
    window.location.assign(`https://survey.joinfound.com/pre-purchase-survey`)
  }

  return (
    <HeaderWrap>
      <div className="header-container">
        <div className="logo-area">
          <a href="/">
            <NewFoundLogo width={'110'} height={'28'} fill={logoFill} />
          </a>
        </div>
        <div className="navigation">
          <BurgerMenu />
          <a className="link" href="https://joinfound.com">
            To Homepage
          </a>
          <a
            className="link continue"
            href="https://survey.joinfound.com/pre-purchase-survey"
            onClick={toPrePurchase}
          >
            Continue
          </a>
        </div>
      </div>
    </HeaderWrap>
  )
}

export default Header
