import React from 'react'
import styled from 'styled-components'

import { FaqCategory } from 'services/api/faq'

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`

const CategoryTile = styled.a`
  border: 2px solid black;
  border-radius: 4px;
  width: 275px;
  display: flex;
  flex-direction: column;
  overflow: cover;
  margin: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  text-align: center;

  h3 {
    padding: 4px;
    text-transform: uppercase;
    font-size: 16px;
  }
`

interface CategoryTilesProps {
  categories: FaqCategory[]
  onCategorySelect: (category: FaqCategory) => void
}

const CategoryTiles: React.VFC<CategoryTilesProps> = ({
  categories,
  onCategorySelect,
}: CategoryTilesProps) => {
  return (
    <CategoriesContainer>
      {categories.map(category => {
        return (
          <CategoryTile
            key={category.listName}
            href={`/?type=${category.listName}`}
            onClick={e => {
              e.preventDefault()
              onCategorySelect(category)
            }}
          >
            <img src={`${category.illustration.fields.file.url}?h=550`} height={275} />
            <h3>{category.title}</h3>
          </CategoryTile>
        )
      })}
    </CategoriesContainer>
  )
}

export default CategoryTiles
