import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'
import { HorizontalNavigationItemProps } from './HorizontalNavigationItem'

const Wrapper = styled.div`
  margin-bottom: 15px;
  @media (max-width: ${theme.breakpoints.md}px) {
    margin: 0 -20px 0 0;
    padding: 0 0 10px 0;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.spacing(1)}px;
  list-style: none;
  padding-left: 0;

  li {
    padding-right: 20px;
  }

  @media (max-width: ${theme.breakpoints.md}px) {
    margin-bottom: 5px;
    li:last-child a {
      margin-right: 20px;
    }

    li:first-child a {
      margin-left: 20px;
    }
  }
`

interface HorizontalNavigationProps {
  children:
    | React.ReactElement<HorizontalNavigationItemProps>
    | Array<React.ReactElement<HorizontalNavigationItemProps>>
}

export const HorizontalNavigation: React.FC<HorizontalNavigationProps> = ({ children }) => {
  return (
    <Wrapper>
      <List>{children}</List>
    </Wrapper>
  )
}
