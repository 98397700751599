import React from 'react'
import styled from 'styled-components'

import IconArrow from 'components/SVG/IconArrow'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  #contactUs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(3)}px 0;
    background-color: ${({ theme }) => theme.colors.darkGreen};
    color: ${({ theme }) => theme.colors.white};
    text-align: center;

    h2 {
      font-size: 57px;
      line-height: 60px;
      font-weight: 700;
    }

    h3 {
      font-family: QuincyCF;
      font-weight: 400;
      font-size: 21px;
      line-height: 29px;
      margin-top: ${({ theme }) => theme.spacing(1)}px;
      margin-bottom: ${({ theme }) => theme.spacing(3)}px;
    }

    a {
      display: block;
      background-color: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.white};
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      padding: 15px 40px;
      margin-bottom: ${({ theme }) => theme.spacing(3)}px;
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-transform: uppercase;
    text-align: center;
    margin: ${({ theme }) => theme.spacing(3)}px auto;

    span {
      flex-grow: 1;
      font-size: 12px;
      margin: auto ${({ theme }) => theme.spacing(3)}px;
      color: ${({ theme }) => theme.colors.darkGray};
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.darkGray};
      }
    }
  }
`

const Footer = () => {
  return (
    <Container>
      <section id="contactUs">
        <h2>Still have questions?</h2>
        <h3>We are here to help!</h3>
        <a href="mailto:support@joinfound.com">
          <span>Contact Us</span>
          <IconArrow margin="0 0 0 15px" fillColor="white" rotateDeg={180} />
        </a>
      </section>
      <footer>
        <span>&copy; 2021-{new Date().getFullYear()} FOUND</span>
        <span>
          <a href="https://joinfound.com/pages/privacy-policy">Privacy Policy</a>
        </span>
        <span>
          <a href="https://joinfound.com/pages/terms-and-conditions">Terms &amp; Conditions</a>
        </span>
        <span>
          <a href="https://joinfound.com/pages/privacy-practices-notice">
            Notice of Privacy Practices
          </a>
        </span>
      </footer>
    </Container>
  )
}

export default Footer
