import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Layout from 'components/Layout'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { HorizontalNavigation, HorizontalNavigationItem } from 'components/HorizontalNavigation'
import {
  getPublicFaqs,
  getCategories,
  Location,
  FaqCategory,
  FaqContent,
  FaqReplaceUrlObject,
  FaqSuggestion,
} from 'services/api/faq'
import { FaqList } from 'components/FaqList'
import { FaqSearch } from 'components/FaqSearch'
import CategoryTiles from 'components/CategoryTiles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing(4)}px;
  margin-right: ${({ theme }) => theme.spacing(4)}px;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  max-width: 90%;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  line-height: 32px;
  margin-bottom: 15px;
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.darkGreen};
  font-weight: 400;
  font-family: QuincyCF;
  font-size: 18px;
`

const MemberFaqPage: React.VFC = () => {
  const router = useRouter()

  const [selectedNavigationItem, setSelectedNavigationItem] = useState<string>()
  const [categories, setCategories] = useState<FaqCategory[]>([])
  const [isFetchingFaqContent, setIsFetchingFaqContent] = useState(false)
  const [errorFetchingFaqContent, setErrorFetchingFaqContent] = useState(false)
  const [faqContent, setFaqContent] = useState<Array<FaqContent>>([])
  const [faqSuggestion, setFaqSuggestion] = useState<FaqSuggestion>()

  useEffect(() => {
    if (faqSuggestion) {
      changeFaqType(faqSuggestion.publicType, faqSuggestion.id)
    }
  }, [faqSuggestion])

  useEffect(() => {
    setActiveFaqFromUrlQuery()
  }, [router.query.type])

  useEffect(() => {
    getCategories(Location.PublicSite).then(async cats => {
      setCategories(cats.items.sort((a, b) => a.title.localeCompare(b.title)))
    })
  }, [])

  const setActiveFaqFromUrlQuery = async () => {
    const queryFaqType = router.query.type as string | undefined

    if (queryFaqType) {
      await fetchFaqContent(queryFaqType)
      setSelectedNavigationItem(queryFaqType)
    } else {
      setSelectedNavigationItem(undefined)
    }
  }

  const changeFaqType = (faqType: string, faqId?: string) => {
    const objToReplace: FaqReplaceUrlObject = {
      query: { type: faqType },
    }
    if (faqId) {
      objToReplace.hash = faqId
    }
    router.push(objToReplace)
  }

  const fetchFaqContent = async (faqType: string) => {
    setErrorFetchingFaqContent(false)
    setIsFetchingFaqContent(true)

    try {
      const faqs = await getPublicFaqs(faqType)
      setFaqContent(faqs.items.map(entry => entry.fields).filter(Boolean))
    } catch {
      setErrorFetchingFaqContent(true)
    } finally {
      setIsFetchingFaqContent(false)
    }
  }
  return (
    <Layout title="Home">
      <>
        <Container>
          <Title>Found Help Center</Title>
          <Subtitle>Just start typing to find what you are looking for...</Subtitle>
          <FaqSearch setFaqSuggestion={setFaqSuggestion} />
          {selectedNavigationItem ? (
            <>
              <HorizontalNavigation>
                {categories?.map(faqType => {
                  return (
                    <HorizontalNavigationItem
                      title={faqType.title}
                      key={faqType.listName}
                      isActive={selectedNavigationItem === faqType.listName}
                      onNavigate={() => changeFaqType(faqType.listName)}
                    />
                  )
                })}
              </HorizontalNavigation>
              <FaqList
                isFetchingFaqContent={isFetchingFaqContent}
                errorFetchingFaqContent={errorFetchingFaqContent}
                faqSuggestion={faqSuggestion}
                faqContent={faqContent}
                faqCategory={selectedNavigationItem}
              />
            </>
          ) : (
            <CategoryTiles
              categories={categories}
              onCategorySelect={cat => changeFaqType(cat.listName)}
            />
          )}
        </Container>

        <Script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/42670050.js"
        />
        <Script type="text/javascript">
          {`
            _linkedin_partner_id = "5646586";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `}
        </Script>
        <Script type="text/javascript">
          {`
            (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);
          `}
        </Script>
      </>
    </Layout>
  )
}

export default MemberFaqPage
