import React from 'react'
import styled from 'styled-components'
import theme from '../styles/theme'

interface BurgerBtnProps {
  isOpen: boolean
  setIsOpen: (a: boolean) => void
  className: string
}

const Burger = styled.div<{ isOpen: boolean }>`
  width: 27px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .burger-btn__line {
    width: 100%;
    height: 2px;
    background: ${theme.colors.darkGreen};
    border-radius: 2px;
  }
`
const BurgerBtn = (props: BurgerBtnProps) => {
  return (
    <Burger
      className={props.className}
      isOpen={props.isOpen}
      onClick={() => {
        if (window.innerWidth <= 576) {
          const body = document.querySelector('body')
          if (body) {
            body.style.overflow = props.isOpen ? 'inherit' : 'hidden'
          }
        }
        props.setIsOpen(!props.isOpen)
      }}
    >
      <div className="burger-btn__line"></div>
      <div className="burger-btn__line"></div>
      <div className="burger-btn__line"></div>
    </Burger>
  )
}

export default BurgerBtn
