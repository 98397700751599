import axios from 'axios'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import { isDevelopment } from 'utils/env'

const isServer = () => typeof window === `undefined`

interface RequestConfig {
  uri: string
  headers?: { [key: string]: string }
  options?: AxiosAuthRefreshRequestConfig
  accessToken?: string
  version?: number
}

export const axiosInstance = axios.create()

const getBaseUrl = (version?: number) => {
  const v = version || '2'
  if (isDevelopment && isServer()) {
    return `${process.env.HYPERWAVE_DOCKER_ENDPOINT}/v${v}`
  } else {
    return `${process.env.HYPERWAVE_ENDPOINT}/v${v}`
  }
}

export async function rawRequest(request: RequestConfig) {
  const baseUrl = getBaseUrl(request.version)
  const uri = `${baseUrl}${request.uri}`

  const options = {
    credentials: 'include',
    headers: request.headers,
  }
  let config = { ...options, ...request.options }
  config = {
    ...config,
    headers: {
      ...(request.accessToken && { Authorization: `JWT ${request.accessToken}` }),
      ...config.headers,
    },
  }
  return axiosInstance(uri, config)
}

export async function request(request: RequestConfig) {
  const rawRequestOptions = {
    ...request,
    headers: {
      Accept: 'application/json; charset=utf-8',
      'Content-Type': 'application/json; charset=utf-8',
      ...request.headers,
    },
  }
  const response = await rawRequest(rawRequestOptions)
  return response?.data
}

export async function unauthedRequest(requestData: RequestConfig) {
  return request({
    ...requestData,
    options: {
      ...requestData.options,
      skipAuthRefresh: true,
    },
  })
}
