import React, { useState } from 'react'
import styled from 'styled-components'
import BurgerBtn from './BurgerBtn'

const Container = styled.div`
  position: fixed;
  top: 56px;
  right: 0;
  left: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  padding-top: 130px;
  background-color: ${props => props.theme.colors.white};

  a {
    font-size: 16px;
    text-decoration: none;
    font-weight: 700;
    display: block;
    margin: ${({ theme }) => theme.spacing(1)}px;
    padding: ${({ theme }) => theme.spacing(4)}px;
    color: ${({ theme }) => theme.colors.darkGreen};
    width: fit-content;
  }

  .continue {
    background-color: ${({ theme }) => theme.colors.darkGreen};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 2px;
  }
`

const CloseBtn = styled.div`
  position: absolute;
  top: 21px;
  right: 30px;
  width: 25px;
  height: 25px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    height: 1.5px;
    width: 100%;
    transform: rotate(45deg);
    background-color: ${props => props.theme.colors.darkGreen};
  }
  &:after {
    transform: rotate(-45deg);
  }
`
const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="burger-menu">
      <BurgerBtn className="mobile-menu-burger" isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen && (
        <Container className="burger-menu__list">
          <CloseBtn
            onClick={() => {
              const body = document.querySelector('body')
              if (body) {
                body.style.overflow = 'inherit'
                setIsOpen(false)
              }
            }}
          />
          <a href="https://joinfound.com">To Homepage</a>
          <a className="continue" href="https://survey.joinfound.com/pre-purchase-survey">
            Continue
          </a>
        </Container>
      )}
    </div>
  )
}

export default BurgerMenu
