export const fadeInCSS = `
animation: fadeIn ease 1s;
-webkit-animation: fadeIn ease 1s;
-moz-animation: fadeIn ease 1s;
-o-animation: fadeIn ease 1s;
-ms-animation: fadeIn ease 1s;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`

export const slideUpCSS = `
position: absolute;

animation: slideUp ease 0.5s;
-webkit-animation: slideUp ease 0.5s;
-moz-animation: slideUp ease 0.5s;
-o-animation: slideUp ease 0.5s;
-ms-animation: slideUp ease 0.5s;

@keyframes slideUp {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@-moz-keyframes slideUp {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@-webkit-keyframes slideUp {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@-o-keyframes slideUp {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@-ms-keyframes slideUp {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}`

export const slideDownCSS = `
position: absolute;

animation: slideDown ease 0.5s;
-webkit-animation: slideDown ease 0.5s;
-moz-animation: slideDown ease 0.5s;
-o-animation: slideDown ease 0.5s;
-ms-animation: slideDown ease 0.5s;

@keyframes slideDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}

@-moz-keyframes slideDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}

@-webkit-keyframes slideDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}

@-o-keyframes slideDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}

@-ms-keyframes slideDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}`
