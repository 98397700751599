import React from 'react'
import Head from 'next/head'
import styled from 'styled-components'

import Header from 'components/Header'
import Footer from 'components/PublicFaqFooter'
import theme from 'styles/theme'
import { AmplitudeExperimentProvider } from 'components/AmplitudeExperiments/AmplitudeExperimentProvider'
import { fadeInCSS } from 'styles/utils'

interface LayoutProps {
  children: React.ReactElement
  title: string
}
const MainContainer = styled.main`
  display: block;
  .page-container {
    display: flex;
    min-height: 90vh;
    overflow: hidden;

    @media (max-width: ${props => props.theme.breakpoints.lg}px) {
      width: 100%;
      height: 100%;
      padding: 0;
    }
    @media (min-width: ${props => props.theme.breakpoints.broader}px) {
      margin: auto;
    }
  }
`

const PageContentWrap = styled.div`
  ${fadeInCSS}

  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    margin-top: 80px;
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}px) {
    margin-top: 80px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}px) {
    margin-top: 58px;
  }

  width: 100%;
  overflow-y: scroll;
`
const Layout = ({ title, children }: LayoutProps): JSX.Element => {
  return (
    <div>
      <AmplitudeExperimentProvider>
        <Head>
          <title>Found Help Center - {title}</title>
        </Head>
        <MainContainer>
          <Header logoFill={theme.colors.greyGreen} />
          <div className="page-container">
            <PageContentWrap id={'page-content'}>{children}</PageContentWrap>
          </div>
          <Footer />
        </MainContainer>
        <div id="modal-root"></div>
      </AmplitudeExperimentProvider>
    </div>
  )
}

export default Layout
