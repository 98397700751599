import React from 'react'
import { useTheme } from 'styled-components'

export const LoadingSpinner = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg width={80} height={80} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" {...props}>
      <circle
        stroke={props.color || theme.colors.blue}
        cx={50}
        cy={50}
        fill="none"
        strokeWidth={8}
        r={35}
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(36.032 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  )
}
