import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { debounce } from 'lodash'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'

import { FaqSuggestion, searchPublicFaqs } from 'services/api/faq'
import theme from 'styles/theme'

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 15px;
`

const SearchContainer = styled.div`
  position: relative;

  .MuiInputBase-input {
    height: 65px;
    box-sizing: border-box;
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid ${theme.colors.black};
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid ${theme.colors.black};
  }

  .search-icon {
    position: absolute;
    bottom: 15%;
    right: 0;
    transform: translateY(-50%);
  }
`

const SuggestionsContainer = styled.div<{ displayBlock: boolean }>`
  max-width: -webkit-fill-available;
  display: ${props => (props.displayBlock ? 'block' : 'none')};
  width: 100%;
  position: absolute;
  padding: 30px;
  margin-top: 10px;
  background-color: ${theme.colors.white};
  border-radius: 30px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 15%);
  z-index: 1;
`

const SuggestionsTitle = styled.div`
  color: ${theme.colors.darkBlue4};
  font-weight: bold;
`

const SuggestionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const SuggestionsItem = styled.li`
  margin-top: 20px;
  font-weight: bold;

  button {
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    font-weight: bold;
    cursor: pointer;
    color: ${theme.colors.darkBlue4};
    font-family: 'QuincyCF', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
      Ubuntu, Cantarell, sans-serif;
    font-size: 16px;
    text-align: left;

    &:hover {
      color: ${theme.colors.darkBlue5};
    }
  }
`

const searchFaqContent = async (query: string) => {
  const faqs = await searchPublicFaqs(query)
  return faqs.items
}

interface FaqSearchProps {
  setFaqSuggestion: React.Dispatch<React.SetStateAction<FaqSuggestion | undefined>>
}

export const FaqSearch = ({ setFaqSuggestion }: FaqSearchProps) => {
  const router = useRouter()
  const [faqSearchContent, setFaqSearchContent] = useState<Array<FaqSuggestion>>([])
  const [errorFetchingFaqContent, setErrorFetchingFaqContent] = useState(false)

  const debouncedHandler = debounce(e => inputHandler(e), 400)

  const inputHandler = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // convert input text to lower case
    const searchQuery = e.target.value.toLowerCase()
    if (searchQuery.length > 2) {
      await retrieveFaqContent(searchQuery)
    }

    if (searchQuery.length === 0) {
      // hide suggestions list
      setFaqSearchContent([])
    }
  }

  const retrieveFaqContent = async (searchQuery: string) => {
    setErrorFetchingFaqContent(false)

    try {
      const faqs = await searchFaqContent(searchQuery)
      setFaqSearchContent(faqs.map(faq => faq.fields).slice(0, 5))
    } catch {
      setErrorFetchingFaqContent(true)
    }
  }

  const handleClickSuggestion = async (faqItem: FaqSuggestion) => {
    setFaqSearchContent([])
    setFaqSuggestion(faqItem)
    await router.replace({
      hash: faqItem.id,
    })
  }

  return (
    <StyledContainer>
      <SearchContainer>
        <TextField
          id="outlined-basic"
          className="search-input"
          onChange={debouncedHandler}
          variant="standard"
          placeholder="e.g. question about my plan"
          fullWidth
        />
        <SearchIcon className="search-icon" />
      </SearchContainer>
      {faqSearchContent.length && !errorFetchingFaqContent ? (
        <SuggestionsContainer displayBlock={!!faqSearchContent.length}>
          <SuggestionsTitle>Suggested Questions</SuggestionsTitle>
          <SuggestionsList>
            {faqSearchContent?.map(faqItem => {
              return (
                <SuggestionsItem key={faqItem.id}>
                  <button onClick={() => handleClickSuggestion(faqItem)}>{faqItem.question}</button>
                </SuggestionsItem>
              )
            })}
          </SuggestionsList>
        </SuggestionsContainer>
      ) : undefined}
    </StyledContainer>
  )
}
