import React from 'react'
import styled from 'styled-components'
interface IconProps {
  fillColor?: string
  rotateDeg?: number
  margin?: string
  width?: number
  height?: number
  viewBox?: string
}
type SvgProps = Omit<IconProps, 'fillColor'>
const StyledSvg = styled.svg<SvgProps>`
  transform: rotate(${({ rotateDeg }) => rotateDeg}deg);
  margin: ${({ margin }) => margin};
`
const IconArrow = (props: IconProps) => {
  return (
    <StyledSvg
      margin={props.margin}
      rotateDeg={props.rotateDeg}
      width={props.width ? props.width : 24}
      height={props.height || 14}
      viewBox={props.viewBox ? props.viewBox : '0 0 24 14'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.fillColor}
        d="M-1.36441e-06 7.78947L-1.22637e-06 6.21053C2.98073 5.89474 5.19058 3.73684 5.39615 2.1883e-06L7.14347 0.421055C6.9379 1.84211 6.47537 3.05263 5.75589 4.10526C5.0364 5.1579 4.16274 5.89474 3.1349 6.21053L24 6.21053L24 7.78948L3.18629 7.78947C3.18629 7.78947 3.18629 7.78947 3.1349 7.78947L3.18629 7.78947C4.21413 8.10526 5.0364 8.78947 5.80728 9.84211C6.52676 10.9474 6.98929 12.1579 7.19486 13.5789L5.44754 14C5.19058 10.2632 2.98073 8.10526 -1.36441e-06 7.78947Z"
      />
    </StyledSvg>
  )
}
export default IconArrow
