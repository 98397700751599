import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'

const Item = styled.li<{ active?: boolean }>`
  p {
    ${props => {
      if (props.active) {
        return `border-bottom: 1px solid ${theme.colors.black};`
      }
    }}
  }
`

const Paragraph = styled.p`
  color: ${theme.colors.black};
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
`

export interface HorizontalNavigationItemProps {
  isActive?: boolean
  onNavigate?: () => void | (() => Promise<void>)
  title: string
}

export const HorizontalNavigationItem: React.FC<HorizontalNavigationItemProps> = ({
  isActive,
  onNavigate,
  title,
}) => {
  return (
    <Item onClick={onNavigate} active={isActive}>
      <Paragraph>{title}</Paragraph>
    </Item>
  )
}
