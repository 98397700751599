import { Asset, EntryCollection } from 'contentful'
import { request } from './base'
import { Document } from '@contentful/rich-text-types'

export enum Location {
  Portal = 'member-portal',
  PublicSite = 'public-site',
}

export interface FaqContent {
  question: string
  answer: Document
  id: string
}

export interface FaqSuggestion extends FaqContent {
  type: string
  publicType: string
}

export interface FaqReplaceUrlObject {
  query: { type: string }
  hash?: string
}

export interface FaqCategory {
  listName: string
  title: string
  illustration: Asset
  articlesCount: number
}

const getFaqs = (type: string, location: Location) =>
  request({
    uri: `/faq/articles?type=${type}&location=${location}`,
    options: { method: 'GET' },
  })

export const getPublicFaqs = (type: string): Promise<EntryCollection<FaqContent>> =>
  getFaqs(type, Location.PublicSite)

function searchFaqs(query: string, location: Location): Promise<EntryCollection<FaqSuggestion>> {
  return request({
    uri: `/faq/search?searchQuery=${query}&location=${location}`,
    options: { method: 'GET' },
  })
}

export const searchPublicFaqs = (query: string) => searchFaqs(query, Location.PublicSite)

export const getCategories = (location: Location): Promise<{ items: FaqCategory[] }> =>
  request({
    uri: `/faq/categories?location=${location}`,
    options: { method: 'GET' },
  })
