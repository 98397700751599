import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaqContent } from 'services/api/faq'
import { LoadingSpinner } from 'components/LoadingSpinner'

import { FaqItem } from './FaqItem'
import { useRouter } from 'next/router'
import { AmplitudeEventTypes, sendAmplitudeEvent } from 'services/amplitude'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
`

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.red};
  font-size: 20px;
`

interface FaqListProps {
  isFetchingFaqContent?: boolean
  errorFetchingFaqContent?: boolean
  faqSuggestion?: FaqContent
  faqContent: Array<FaqContent>
  faqCategory?: string
}

interface OnFaqItemSelectFactoryParams {
  id: string
  question: string
}

export const FaqList: React.VFC<FaqListProps> = ({
  isFetchingFaqContent,
  errorFetchingFaqContent,
  faqSuggestion,
  faqContent,
  faqCategory,
}) => {
  const router = useRouter()
  const [selectedFaqItemId, setSelectedFaqItemId] = useState<string>()

  useEffect(() => {
    if (faqSuggestion) {
      setSelectedFaqItemId(faqSuggestion.id)
      document.getElementById(window.location.hash)?.scrollIntoView()
    }
  }, [faqSuggestion])

  const clearSelectedFaqItemId = () => {
    setSelectedFaqItemId(undefined)
  }

  useEffect(() => {
    setSelectedFaqItemFromUrlHash()

    const listener = () => setSelectedFaqItemFromUrlHash()

    window.addEventListener('hashchange', listener)

    return () => {
      window.removeEventListener('hashchange', listener)
    }
  }, [])

  const setSelectedFaqItemFromUrlHash = () => {
    // Get the URL hash excluding #
    const faqItemId = window.location.hash.substring(1)

    if (faqItemId) {
      setSelectedFaqItemId(faqItemId)
      document.getElementById(window.location.hash)?.scrollIntoView()
    } else {
      clearSelectedFaqItemId()
    }
  }

  const onFaqItemSelectFactory = ({ id, question }: OnFaqItemSelectFactoryParams) => {
    return async () => {
      sendAmplitudeEvent(AmplitudeEventTypes.PublicFAQClicked, {
        question,
        category: faqCategory,
      })

      setSelectedFaqItemId(id)
      await router.push({
        hash: id,
      })
    }
  }

  return (
    <ContentContainer>
      {isFetchingFaqContent ? (
        <LoadingSpinner width={40} height={40} />
      ) : errorFetchingFaqContent ? (
        <ErrorMessage>There was a problem loading the content</ErrorMessage>
      ) : (
        faqContent.map(({ question, answer, id }) => (
          <FaqItem
            question={question}
            answer={answer}
            id={id}
            key={id}
            isSelected={selectedFaqItemId === id}
            onSelect={onFaqItemSelectFactory({ id, question })}
            onClose={clearSelectedFaqItemId}
          />
        ))
      )}
    </ContentContainer>
  )
}
